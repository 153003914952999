













































import {Component} from 'vue-property-decorator';
import Footer from '../components/Footer.vue'
import AbpBase from '../lib/abpbase'
import ResetPasswordInput from "@/store/entities/reset-password-input";

@Component({
    components: {Footer}
})
export default class ResetPassword extends AbpBase {
    private resetPasswordInput: ResetPasswordInput = new ResetPasswordInput();

    async save() {
        (this.$refs.resetPassword as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'app/resetPassword',
                    data: this.resetPasswordInput
                })
                await this.$router.push('login');
            }
        });
    }

    validatePassCheck() {
        let that = this;
        return (rule: any, value: any, callback: any) => {
            if (!that.resetPasswordInput.repeatPassword) {
                callback(new Error(this.L('FieldIsRequired')));
            } else if (that.resetPasswordInput.repeatPassword !== that.resetPasswordInput.password) {
                callback(new Error(this.L('ConfirmPasswordNotMatch')));
            } else {
                callback();
            }
        };
    }

    rules = {
        password: [{
            required: true,
            message: this.L('FieldIsRequired', undefined, this.L('Password')),
            trigger: 'blur'
        }],
        passwordRepeat: {
            validator: this.validatePassCheck(), trigger: 'blur'
        }
    }


    created() {
        this.resetPasswordInput = new ResetPasswordInput();
        this.resetPasswordInput.userId = parseInt(this.$route.query.userId.toString());
        this.resetPasswordInput.resetCode = this.$route.query.code.toString();
    }
}
